import { useCallback, useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import { useEventTracking } from '../../hooks/use-event-tracking';

const progressThresholds = [25, 50, 75];

const trackingData = {
  component_name: 'roche-video-player',
  event: 'video_tracking',
};

const getPercentagesToTrack = (tracked: number[]) => (
  progressThresholds.filter((threshold) => !tracked.includes(threshold))
);

export const VideoPlayerTracking = () => {
  const { trackEvent } = useTracking({});
  const [videos, setVideos] = useState({});

  const trackProgress = useCallback(({ id, title, percentage }) => {
    trackEvent({
      ...trackingData,
      value: 'progress',
      video_percentage: percentage,
      videoId: id,
      video_title: title,
    });
  }, [trackEvent]);

  useEffect(() => {
    const handleProgress = (e: CustomEvent) => {
      const {
        detail: {
          videoPercentage,
          videoId,
          videoTitle,
          videoloop,
        },
      } = e;

      if (videoloop) return;

      const alreadyTrackedPercentages = videos[videoId] || [];
      const percentages = getPercentagesToTrack(alreadyTrackedPercentages)
        .filter((percentage) => percentage <= videoPercentage);

      percentages.forEach((percentage) => (
        trackProgress({
          id: videoId,
          title: videoTitle,
          percentage,
        })
      ));

      setVideos({
        ...videos,
        [videoId]: [...alreadyTrackedPercentages, ...percentages],
      });
    };

    window.addEventListener('rocheVideoPlayerProgress', handleProgress);
    return () => {
      window.removeEventListener('rocheVideoPlayerProgress', handleProgress);
    };
  }, [videos]);

  useEventTracking('rocheVideoPlayerPlay', ({ detail }) => {
    if (detail.videoloop) return null;

    setVideos((videos) => ({ ...videos, [detail.videoId]: [] }));
    return {
      ...trackingData,
      value: 'play',
      video_title: detail.videoTitle,
      video_percentage: detail.videoPercentage,
      videoId: detail.videoId,
    };
  });

  useEventTracking('rocheVideoPlayerPause', ({ detail }) => {
    if (detail.videoloop) return null;

    return {
      ...trackingData,
      value: 'pause',
      video_title: detail.videoTitle,
      video_percentage: detail.videoPercentage,
      videoId: detail.videoId,
    };
  });

  useEventTracking('rocheVideoPlayerComplete', ({ detail }) => {
    if (detail.videoloop) return null;

    return {
      ...trackingData,
      value: 'complete',
      video_title: detail.videoTitle,
      video_percentage: detail.videoPercentage,
      videoId: detail.videoId,
    };
  });

  return null;
};
