import { useEventTracking } from '../../hooks/use-event-tracking';

export const ButtonTracking = () => {
  useEventTracking('rocheButtonClick', ({ detail: { text, href } }) => {
    const isLinkButton = !!href;
    if (isLinkButton) return null;

    return {
      event: 'button_click',
      component_name: 'roche-button',
      button_label: text,
    };
  });

  return null;
};
