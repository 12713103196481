import React, { PropsWithChildren } from 'react';
import { useTracking } from 'react-tracking';
import { VideoPlayerTracking } from './global/video-player-tracking';
import { FormTracking } from './global/form-tracking';
import { TrackingContext, TrackingContextProps } from './context';
import { PageViewTracking } from './global/page-view-tracking';
import { HeaderTracking } from './global/header-tracking';
import { PageScrollTracking } from './global/page-scroll-tracking';
import { ListSearchTracking } from './global/list-search-tracking';
import { ButtonTracking } from './global/button-tracking';
import { DownloadTracking } from './global/download-tracking';
import { LinkTracking } from './global/link-tracking';

export interface TrackingData {
  event: string;
  component_name: string;
  [key: string]: string | number | boolean | null; // For additional dynamic properties
}

type TrackingProps = PropsWithChildren & TrackingContextProps;

export const Tracking = ({
  children,
  ...props
}: TrackingProps) => {
  const { Track } = useTracking(
    {},
    {
      dispatch: (data: TrackingData) => {
        const trackingData = {
          project_name: process.env.GATSBY_WEBSITE_DOMAIN || 'roche-dot-com',
          ...data,
        };
        console.log('pushing tracking data', trackingData);
        // console.table(trackingData);
        if (!window.dataLayer) {
          window.dataLayer = [];
        }

        window.dataLayer.push(trackingData);
      },
    },
  );

  return (
    <Track>
      <TrackingContext.Provider value={props}>
        <PageViewTracking />
        <PageScrollTracking />
        <HeaderTracking />
        <VideoPlayerTracking />
        <FormTracking />
        <ListSearchTracking />
        <ButtonTracking />
        <DownloadTracking />
        <LinkTracking />
      </TrackingContext.Provider>
      {children}
    </Track>
  );
};
