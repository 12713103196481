import { NavigationClickEvent } from '../../../components/custom/navigation';

export const buildRocheNavigationTrackingData = ({
  text,
  url,
  level,
  parent,
  action,
}: NavigationClickEvent) => ({
  event: 'navigation_click',
  click_text: text,
  click_url: url,
  navigation_level: level,
  navigation_name: parent || text,
  component_action: action,
});
