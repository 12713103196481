import { useEventTracking } from '../../hooks/use-event-tracking';

export const ListSearchTracking = () => {
  useEventTracking('rocheListSearchFilter', ({ detail }) => ({
    event: 'filter_search',
    component_name: 'roche-list-search',
    term: (detail as string)?.toLowerCase(),
  }));

  return null;
};
