import { useContext, useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { TrackingContext } from '../context';
import { getCookieValue } from '../../utils/get-cookies';
import { getSlugForLocale } from '../../utils/get-slug-from-locals';
import { StoryblokLinkNode } from '../../services/navigation/types';
import { LanguageService } from '../../services';

type PageCategoryKeys = {
  [key: `page_category${number}`]: string;
}

type PageViewTracking = {
  event: string;
  page_type:string,
  traffic_type: string,
  page_language: string,
  page_path: string,
  page_title: string,
  page_url: string,
  page_path_global: string,
} & PageCategoryKeys;

const initialPageCategories = Object.fromEntries(Array.from({ length: 5 }, (_, i) => [`page_category${i + 1}`, null]));

const getCategoriesObject = (slugs: string[]): PageCategoryKeys => (
  slugs.reduce((accumulator, slug, index) => ({
    ...accumulator,
    [`page_category${index + 1}`]: slug,
  }), initialPageCategories)
);

const getSlugsFromBreadcrumbs = (breadcrumbs: StoryblokLinkNode[]): string[] => (
  breadcrumbs
    .filter((item) => item.is_folder)
    .map((item) => item.slug)
);

const getSlugsFromPathname = (pathname: string): string[] => (
  pathname
    .slice(1)
    .split('/')
    .reduce((accumulator, current, index, array) => (
      [...accumulator, [...array.slice(0, index), current].join('/')]
    ), [])
    .slice(0, -1)
);

export const PageViewTracking = () => {
  const { trackEvent } = useTracking<PageViewTracking>({ event: 'pageview' });
  const {
    slug,
    content,
    alternates,
    allSpaceLocales,
    locale,
    breadcrumbs,
  } = useContext(TrackingContext);

  const getPageType = () => (
    content?.component || window.location.pathname.slice(1).split('/').shift()
  );

  const getLanguage = () => (
    locale || LanguageService.getLocaleFromSlug(window.location.pathname)
  );

  const getPath = () => (
    slug || window.location.pathname.split('/').pop()
  );

  const getPathGlobal = () => (
    (alternates && allSpaceLocales
      ? getSlugForLocale(alternates, allSpaceLocales) : null) || null
  );

  const getCategories = () => {
    const slugs = breadcrumbs
      ? getSlugsFromBreadcrumbs(breadcrumbs)
      : getSlugsFromPathname(window.location.pathname);

    return getCategoriesObject(slugs);
  };

  useEffect(() => {
    trackEvent({
      page_type: getPageType(),
      traffic_type: getCookieValue('netLocation'),
      page_language: getLanguage(),
      page_path: getPath(),
      page_title: window.document.title,
      page_url: window.location.href,
      page_path_global: getPathGlobal(),
      ...getCategories(),
    });
  }, []);

  return null;
};
