import { useEventTracking } from '../../hooks/use-event-tracking';

export const LinkTracking = () => {
  useEventTracking('rocheButtonClick', ({ detail: { text, href } }) => {
    const isLinkButton = !!href;
    if (!isLinkButton) return null;

    return {
      event: 'link_click',
      component_name: 'roche-button',
      type: 'button',
      click_url: href,
      click_text: text,
    };
  });

  useEventTracking('rocheTeaserClick', ({ detail: { isDownloadLink, teaserTitle, teaserUrl } }) => {
    if (isDownloadLink) return null;

    return {
      event: 'link_click',
      component_name: 'roche-button',
      type: 'teaser',
      click_url: teaserUrl,
      click_text: teaserTitle,
    };
  });

  useEventTracking('rocheTextLinkClick', ({ detail: { href, text } }) => ({
    event: 'link_click',
    component_name: 'roche-text-link',
    type: 'teaser',
    click_url: href,
    click_text: text,
  }));

  useEventTracking('rocheTagClick', ({ detail: { href, tag } }) => ({
    event: 'link_click',
    component_name: 'roche-tag',
    type: 'topic',
    click_url: href,
    click_text: tag,
  }));

  return null;
};
