/* eslint-disable max-len */
import { Alternate } from '../templates/types';

/**
 * Finds the slug of an alternate entry based on the provided locale.
 * If the specific locale is not found, it will find the slug without a known locale.
 * @param {Alternate[]} alternates - An array of alternate objects to search through.
 * @param {string[]} knownLocales - An array of known locale prefixes.
 * @returns {string | undefined} - The slug without a locale prefix if found, or undefined if not found.
 */
export const getSlugForLocale = (alternates: Alternate[], knownLocales: string[]): string | undefined => {
  let defaultSlug: string;
  const localePrefixRegex = new RegExp(`^(${knownLocales.join('|')})/`);

  alternates.forEach((item) => {
    if (!localePrefixRegex.test(item.fullSlug)) {
      defaultSlug = item.fullSlug;
    }
  });
  return defaultSlug;
};
