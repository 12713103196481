import { useEventTracking } from '../../hooks/use-event-tracking';
import { buildRocheNavigationTrackingData } from '../utils/roche-navigation';

export const HeaderTracking = () => {
  useEventTracking('rocheMobileNavigationSearchClick', ({ detail }) => (
    buildRocheNavigationTrackingData({
      text: 'Search',
      url: detail.url,
      level: 1,
      parent: 'Search',
      action: 'navigate',
    })
  ));

  useEventTracking('rocheOffcanvasTrigger', ({ detail }) => (
    buildRocheNavigationTrackingData({
      text: 'Offcanvas click',
      url: null,
      level: 1,
      parent: 'Offcanvas',
      action: detail.action === 'close' ? 'close' : 'expand',
    })
  ));

  return null;
};
