import { createContext } from 'react';
import { StoryblokComponent } from 'storyblok-js-client';
import { Alternate, StoryDataFromGraphQLQuery } from '../templates/types';
import { StoryblokLinkNode } from '../services/navigation/types';

export type TrackingContextProps = {
  locale: string;
  breadcrumbs: StoryblokLinkNode[];
  slug: string,
  content: StoryblokComponent<string> & {
    [index: string]: any;
  },
  alternates: Alternate[],
  allSpaceLocales: string[]
}

export const TrackingContext = createContext<TrackingContextProps>(null);

export const getTrackingContextProps = (state: Record<string, any>): TrackingContextProps => {
  const { story, breadcrumbs, locale } = state;
  const {
    slug, content, alternates, allSpaceLocales,
  } = (story as StoryDataFromGraphQLQuery) ?? {};

  return {
    breadcrumbs,
    locale,
    slug,
    content,
    alternates,
    allSpaceLocales,
  };
};
