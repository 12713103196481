import { useEventTracking } from '../../hooks/use-event-tracking';

export const FormTracking = () => {
  const trackingData = {
    event: 'form',
    component_name: 'roche-form',
  };

  useEventTracking('rocheFormLoad', () => ({
    ...trackingData,
    component_action: 'load',
  }));

  useEventTracking('rocheFormSubmit', () => ({
    ...trackingData,
    component_action: 'submit',
  }));

  useEventTracking('rocheFormSuccess', () => ({
    ...trackingData,
    component_action: 'success',
  }));

  return null;
};
