/**
 * Utility function to get the value of a specific cookie by name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string|null} - The value of the cookie if found, otherwise null.
 */
export const getCookieValue = (name: string): string | null => {
  const cookiesArray = document.cookie.split(';');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookiesArray.length; i++) {
    const cookiePair = cookiesArray[i].split('=');

    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }

  return null;
};
