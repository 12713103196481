import { useEventTracking } from '../../hooks/use-event-tracking';

const getFileInformation = (url: string) => {
  const file = url.split('/').pop().split('.');
  const extension = file.pop();
  const name = file.join('.');

  return [name, extension];
};

export const DownloadTracking = () => {
  useEventTracking('rocheTeaserClick', ({ detail: { isDownloadLink, teaserUrl } }) => {
    if (!isDownloadLink || !teaserUrl) return null;

    const [filename, extension] = getFileInformation(teaserUrl);

    return {
      event: 'download',
      component_name: 'roche-download-teaser',
      file_type: extension,
      file_name: filename,
    };
  });

  return null;
};
