import React, { useCallback, useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';

type PageScrollTracking = {
  event: string;
  page_scroll_depth: string;
};
const getDepthsToTrack = (scrollDepth: number, depths: number[]) => (
  depths.filter((depth) => scrollDepth >= depth)
);

const getRemainingDepths = (depths: number[], alreadyTracked: number[]) => (
  depths.filter((depth): boolean => !alreadyTracked.includes(depth))
);
const getMappedDepth = (depth: number) => {
  const map = { 95: 100 };
  const percentage = depth * 100;
  return map[percentage] || percentage;
};

const trackingDepths = [0.25, 0.50, 0.75, 0.95];

const useScrollDepthTracking = () => {
  const { trackEvent } = useTracking<PageScrollTracking>({ event: 'page_scroll_depth' });
  const [trackedDepths, setTrackedDepths] = useState<number[]>([]);

  const checkScrollDepth = useCallback(() => {
    const { scrollTop } = document.documentElement;
    const windowHeight = window.innerHeight;
    const docHeight = document.documentElement.offsetHeight;

    const totalDocScrollLength = docHeight - windowHeight;
    const scrollPosition = scrollTop / totalDocScrollLength;

    const depthsToTrack = getDepthsToTrack(scrollPosition, trackingDepths);
    const newDepthsToTrack = getRemainingDepths(depthsToTrack, trackedDepths);

    newDepthsToTrack.forEach((depth) => {
      trackEvent({
        page_scroll_depth: `${getMappedDepth(depth)}%`,
      });
      setTrackedDepths((currentDepths) => [...currentDepths, depth]);
    });
  }, [trackEvent, trackedDepths]);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollDepth);
    return () => window.removeEventListener('scroll', checkScrollDepth);
  }, [checkScrollDepth]);

  return null;
};

export const PageScrollTracking = () => {
  useScrollDepthTracking();

  useEffect(() => {
    document.body.style.position = 'relative';
  }, []);

  return (
    <div
      style={{
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
      }}>
    </div>
  );
};
